











































































































































































































































































































































































































































































































































import { Vue, Component, Prop} from 'vue-property-decorator';
import {initSmoothScroll} from "@/utils/smooth-scroll";
import {handleFragmentScroll, initViewportForSp, revertViewport} from '@/utils/lp-handler';
import FooterComponent from "./components/FooterComponent.vue";
import {dispatchInitialized} from "@/libs/cypress";

let originalViewport = '';

@Component({
  components: {
    FooterComponent
  }
})
export default class extends Vue {
  private drawerVisible:boolean = false;
  private clientItems = [
    { src: require("@/assets/clients/kinoshita.svg"), class: "kinoshita-slide", url: "https://puffoods.jp/" },
    { src: require("@/assets/clients/two-inc.png"), class: "two-inc-slide", url: "https://two2.jp/" },
    { src: require("@/assets/clients/takahashi-source.svg"), class: "takahashi-slide", url: "https://www.takahashisauce.com/" },
    { src: require("@/assets/clients/vegetech.svg"), class: "vegetech-slide", url: "https://www.vegetech.co.jp/" },
    { src: require("@/assets/clients/centara-grand-hotel-osaka.svg"), class: "centara-slide", url: "https://www.centarahotelsresorts.com/centaragrand/ja/cgoj" },
    { src: require("@/assets/clients/asanoya.svg"), class: "asanoya-slide", url: "https://b-asanoya.com/" },
    { src: require("@/assets/clients/ryushodo.svg"), class: "ryushodo-slide", url: "https://www.ryushodo.com/" },
    { src: require("@/assets/clients/ovgobaker.svg"), class: "ovgobaker-slide", url: "https://ovgobaker.com/" },
    { src: require("@/assets/clients/briant.svg"), class: "briant-slide", url: "https://briant.yokacorp.com/" },
    { src: require("@/assets/clients/vanillabeans.svg"), class: "vanillabeans-slide", url: "https://www.vanillabeans.yokohama/" },
  ];

  protected mounted() {
    initViewportForSp();
    initSmoothScroll();
    handleFragmentScroll();

    dispatchInitialized();
  }
  private beforeDestroy() {
    revertViewport();
  }
}
