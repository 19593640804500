


























































































































































































































































































































































































































































import { Vue, Component, Prop} from 'vue-property-decorator';
import {initSmoothScroll} from "@/utils/smooth-scroll";
import {handleFragmentScroll, initViewportForSp, revertViewport} from '@/utils/lp-handler';
import FooterComponent from "./components/FooterComponent.vue";
import LogoSpec from "@/components/Logo/LogoSpec.vue";
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {
    LogoSpec,
    FooterComponent
  }
})
export default class extends Vue {
  private drawerVisible:boolean = false;
  private clientItems = [
    { src: require("@/assets/clients/sojitz.svg"), class: "sojitz-slide", url: "https://www.sojitz.com/jp/" },
    { src: require("@/assets/clients/two-inc.png"), class: "two-inc-slide", url: "https://two2.jp/" },
    { src: require("@/assets/clients/takahashi-source.svg"), class: "takahashi-slide", url: "https://www.takahashisauce.com/" },
    { src: require("@/assets/clients/vegetech.svg"), class: "vegetech-slide", url: "https://www.vegetech.co.jp/" },
    { src: require("@/assets/clients/obama-kaisan.svg"), class: "obama-kaisan-slide", url: "https://obamakaisanbutsu.jp/" },
    { src: require("@/assets/clients/alpha-food.svg"), class: "alpha-food-slide", url: "https://www.alpha-food.co.jp/" },
    { src: require("@/assets/clients/asanoya.svg"), class: "asanoya-slide", url: "https://b-asanoya.com/" },
    { src: require("@/assets/clients/kinoshita.svg"), class: "kinoshita-slide", url: "https://puffoods.jp/" },
    { src: require("@/assets/clients/cix.svg"), class: "cix-slide", url: "https://www.cix-hd.com/" },
    { src: require("@/assets/clients/briant.svg"), class: "briant-slide", url: "https://briant.yokacorp.com/" },
  ];

  protected mounted() {
    initViewportForSp();
    initSmoothScroll();
    handleFragmentScroll();

    dispatchInitialized();
  }
  private beforeDestroy() {
    revertViewport();
  }
}
