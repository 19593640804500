

























































































































































































































































import { Vue, Component, Prop} from 'vue-property-decorator';
import {initSmoothScroll} from "@/utils/smooth-scroll";
import {handleFragmentScroll, initViewportForSp, revertViewport} from '@/utils/lp-handler';
import FooterComponentAgency from './FooterComponentAgency.vue';
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {
    FooterComponentAgency
  }
})
export default class extends Vue {
  private drawerVisible:boolean = false;

  protected mounted() {
    initViewportForSp();
    initSmoothScroll();
    handleFragmentScroll();

    dispatchInitialized();
  }
  private beforeDestroy() {
    revertViewport();
  }

}
