export function initSmoothScroll(selector = 'a[href*="#"]') {
  document.querySelectorAll(selector).forEach(function($a) {
    $a.addEventListener('click', function(e) {
      const href = $a.href.split('#')[1];
      const $target = document.getElementById(href);

      if ($target) {
        const fixed = document.querySelector('[data-scroll-header]');
        const offset = (fixed && fixed.classList.contains(stickyClass)) ? fixed.clientHeight : 0;

        window.scrollTo({
          top: window.pageYOffset + $target.getBoundingClientRect().top - offset,
          behavior: 'smooth'
        })
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      e.preventDefault();
      return false;
    });
  });
}

