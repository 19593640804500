export function handleFragmentScroll() {
  if (location.hash && document.querySelector(location.hash)) {
    document.querySelector(location.hash)!.scrollIntoView(true);
  }
}

let originalViewport = document.querySelector("meta[name='viewport']")!.getAttribute('content')!;
export function initViewportForSp() {
  document.querySelector("meta[name='viewport']")!.setAttribute("content", 'width=device-width, initial-scale=1');
}
export function revertViewport() {
  document.querySelector("meta[name='viewport']")!.setAttribute("content", originalViewport);
}
