








import { Vue, Component, Prop} from 'vue-property-decorator';
import {initViewportForSp, revertViewport} from '@/utils/lp-handler';

var widgetOptions353709 = {
  bg_color: "transparent"
};
@Component
export default class extends Vue {
  protected mounted() {
    (function() {
      var a = document.createElement("script"), h = "head";
      a.async = true;
      a.src = (document.location.protocol == "https:" ? "https:" : "http:") + "//ucalc.pro/api/widget.js id=353709&t="+Math.floor(new Date().getTime()/18e5);
      document.getElementsByTagName(h)[0].appendChild(a)
    })();

    initViewportForSp();
  }
  private beforeDestroy() {
    revertViewport();
  }

}
